
    import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
    import Util from '@/lib/util'
    import AbpBase from '@/lib/abpbase'
    import PageRequest from '@/store/entities/page-request'
    import SortingModel from "@/store/entities/sortingmodel";
    import CompanyInstructionDto from "@/store/entities/companycontext/companyinstruction";
    import CompanyInstructionForm from "@/views/setting/companycontext/companyinstruction/companyinstruction-form.vue";

    class PageCompanyInstructionRequest extends PageRequest {
        companyId: number;
    }

    @Component({
        components: {CompanyInstructionForm}
    })
    export default class CompanyInstruction extends AbpBase {
        @Prop({default: -1}) companyId: number;

        async edit(instructionId: number) {
            this.$store.commit('companyInstruction/edit', await this.$store.dispatch({
                type: 'companyInstruction/get',
                data: instructionId
            }));
            this.editModalShow = true;
            await this.getpage();
        }

        async pagesizeChange(pagesize: number) {
            this.$store.commit('companyInstruction/setPageSize', pagesize);
            await this.getpage();
        }

        pagerequest: PageCompanyInstructionRequest = new PageCompanyInstructionRequest();

        createModalShow: boolean = false;
        editModalShow: boolean = false;

        async search() {
            await this.pageChange(1);
        }

        async changeSort(data) {
            if (this.pagerequest.sorting == null) {
                this.pagerequest.sorting = [];
            }
            let item = this.pagerequest.sorting.find(x => x.fieldName === data.key)
            if (item == null) {
                let sortingModel = new SortingModel();
                sortingModel.fieldName = data.key;
                sortingModel.isDescendingDirection = data.order === "desc";
                this.pagerequest.sorting.push(sortingModel)
            } else {
                item.isDescendingDirection = data.order === "desc";
            }
            await this.getpage()
        }

        get list() {
            return this.$store.state.companyInstruction.list;
        };

        get loading() {
            return this.$store.state.companyInstruction.loading;
        }

        async create() {
            let companyInstruction = new CompanyInstructionDto();
            companyInstruction.companyId = this.companyId;
            this.$store.commit('companyInstruction/edit', companyInstruction);
            this.createModalShow = true;
        }

        async pageChange(page: number) {
            this.$store.commit('companyInstruction/setCurrentPage', page);
            await this.getpage();
        }

        async getpage() {

            this.pagerequest.maxResultCount = this.pageSize;
            this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize;

            await this.$store.dispatch({
                type: 'companyInstruction/getAll',
                data: this.pagerequest
            })
        }

        get pageSize() {
            return this.$store.state.companyInstruction.pageSize;
        }

        get totalCount() {
            return this.$store.state.companyInstruction.totalCount;
        }

        get currentPage() {
            return this.$store.state.companyInstruction.currentPage;
        }

        columns = [{
            title: this.L('Name'),
            key: 'name',
            sortable: 'custom'
        }, {
            title: this.L('Type'),
            key: 'type'
        }, {
            title: '',
            key: 'id',
            width: 80,
            sortable: 'custom'
        }, {
            title: this.L('Actions'),
            key: 'Actions',
            width: 150,
            render: (h: any, params: any) => {
                return h('div', [
                    h('Button', {
                        props: {
                            type: 'primary',
                            size: 'small'
                        },
                        style: {
                            marginRight: '5px'
                        },
                        on: {
                            click: async () => {
                                await this.edit(params.row.id);
                            }
                        }
                    }, this.L('Edit')),
                    h('Button', {
                        props: {
                            type: 'error',
                            size: 'small'
                        },
                        on: {
                            click: async () => {
                                this.$Modal.confirm({
                                    title: this.L('Tips'),
                                    content: this.L('Delete Address Confirm'),
                                    okText: this.L('Yes'),
                                    cancelText: this.L('No'),
                                    onOk: async () => {
                                        await this.$store.dispatch({
                                            type: 'companyInstruction/delete',
                                            data: params.row
                                        })
                                        await this.getpage();
                                    }
                                })
                            }
                        }
                    }, this.L('Delete'))
                ])
            }
        }]

        async mounted() {
            this.pagerequest.companyId = this.companyId;
            await this.getpage();
        }
    }
