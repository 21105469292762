
    import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
    import Util from '@/lib/util'
    import AbpBase from '@/lib/abpbase'
    import PageRequest from '@/store/entities/page-request'
    import SortingModel from "@/store/entities/sortingmodel";

    class PageAddressRequest extends PageRequest {
        companyId: number;
    }

    @Component({})
    export default class Address extends AbpBase {

        props: {
            companyId: number
        }

        async edit(addressId: number) {
            await this.$router.push({
                name: 'address-form',
                params: {addressId: addressId.toString()}
            });
            await this.getpage();
        }

        //filters
        pagerequest: PageAddressRequest = new PageAddressRequest();

        createModalShow: boolean = false;
        editModalShow: boolean = false;

        async search() {
            await this.pageChange(1);
        }

        async changeSort(data) {
            if (this.pagerequest.sorting == null) {
                this.pagerequest.sorting = [];
            }
            let item = this.pagerequest.sorting.find(x=>x.fieldName === data.key)
            if (item == null){
                let sortingModel = new SortingModel();
                sortingModel.fieldName = data.key;
                sortingModel.isDescendingDirection = data.order === "desc";
                this.pagerequest.sorting.push(sortingModel)
            }
            else{
                item.isDescendingDirection = data.order === "desc";
            }
            await this.getpage()
        }

        get list() {
            return this.$store.state.address.list;
        };

        get loading() {
            return this.$store.state.address.loading;
        }

        async create() {
            await this.$router.push({
                name: 'address-form',
                params: {companyId: this.pagerequest.companyId.toString()}
            });
            await this.getpage();
        }

        async pageChange(page: number) {
            this.$store.commit('address/setCurrentPage', page);
            await this.getpage();
        }

        async pagesizeChange(pagesize: number) {
            this.$store.commit('address/setPageSize', pagesize);
            await this.getpage();
        }

        async getpage() {

            this.pagerequest.maxResultCount = this.pageSize;
            this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize;

            await this.$store.dispatch({
                type: 'address/getAll',
                data: this.pagerequest
            })
        }

        get pageSize() {
            return this.$store.state.address.pageSize;
        }

        get totalCount() {
            return this.$store.state.address.totalCount;
        }

        get currentPage() {
            return this.$store.state.address.currentPage;
        }

        columns = [{
            title: this.L('Name'),
            key: 'name',
            sortable: 'custom'
        }, {
            title: this.L('Address'),
            key: 'address',
            sortable: 'custom'
        }, {
            title: this.L('Country'),
            key: 'country',
            sortable: 'custom'
        }, {
            title: this.L('Actions'),
            key: 'Actions',
            width: 150,
            render: (h: any, params: any) => {
                return h('div', [
                    h('Button', {
                        props: {
                            type: 'primary',
                            size: 'small'
                        },
                        style: {
                            marginRight: '5px'
                        },
                        on: {
                            click: async () => {
                                await this.edit(params.row.id);
                            }
                        }
                    }, this.L('Edit')),
                    h('Button', {
                        props: {
                            type: 'error',
                            size: 'small'
                        },
                        on: {
                            click: async () => {
                                this.$Modal.confirm({
                                    title: this.L('Tips'),
                                    content: this.L('Delete Address Confirm'),
                                    okText: this.L('Yes'),
                                    cancelText: this.L('No'),
                                    onOk: async () => {
                                        await this.$store.dispatch({
                                            type: 'address/delete',
                                            data: params.row
                                        })
                                        await this.getpage();
                                    }
                                })
                            }
                        }
                    }, this.L('Delete'))
                ])
            }
        }]

        async created() {
            this.pagerequest.companyId = parseInt(this.$attrs["companyId"]);
            await this.getpage();
        }
    }
