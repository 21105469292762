
    import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
    import AbpBase from '@/lib/abpbase'
    import PageRequest from '@/store/entities/page-request'
    import CompanyRelationshipForm from './companyrelationship-form.vue'

    class PageCompanyRelationshipRequest extends PageRequest {
        keyword: string;
        companyId: number;
    }

    @Component({
        components: {CompanyRelationshipForm}
    })
    export default class CompanyRelationship extends AbpBase {

        props: {
            companyId: number
        }

        async edit(id: number) {
            this.$store.commit('companyRelationship/edit', await this.$store.dispatch({
                type: 'companyRelationship/get',
                data: id
            }));
            this.editModalShow = true;
        }

        //filters
        pagerequest: PageCompanyRelationshipRequest = new PageCompanyRelationshipRequest();

        createModalShow: boolean = false;
        editModalShow: boolean = false;

        get list() {
            return this.$store.state.companyRelationship.list;
        };

        get loading() {
            return this.$store.state.companyRelationship.loading;
        }

        create() {
            this.$store.commit('companyRelationship/edit', new CompanyRelationship());
            this.createModalShow = true;
        }

        pageChange(page: number) {
            this.$store.commit('companyRelationship/setCurrentPage', page);
            this.getpage();
        }

        pagesizeChange(pagesize: number) {
            this.$store.commit('companyRelationship/setPageSize', pagesize);
            this.getpage();
        }

        async getpage() {

            this.pagerequest.maxResultCount = this.pageSize;
            this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize;

            await this.$store.dispatch({
                type: 'companyRelationship/getAll',
                data: this.pagerequest
            })
        }

        get pageSize() {
            return this.$store.state.companyRelationship.pageSize;
        }

        get totalCount() {
            return this.$store.state.companyRelationship.totalCount;
        }

        get currentPage() {
            return this.$store.state.companyRelationship.currentPage;
        }

        columns = [{
            title: this.L('Company'),
            key: 'relatedCompanyName'
        }, {
            title: this.L('Origin'),
            key: 'origin'
        }, {
            title: this.L('Destination'),
            key: 'destination'
        }, {
            title: this.L('Actions'),
            key: 'Actions',
            width: 150,
            render: (h: any, params: any) => {
                return h('div', [
                    h('Button', {
                        props: {
                            type: 'primary',
                            size: 'small'
                        },
                        style: {
                            marginRight: '5px'
                        },
                        on: {
                            click: async () => {
                                await this.edit(params.row.id);
                            }
                        }
                    }, this.L('Edit')),
                    h('Button', {
                        props: {
                            type: 'error',
                            size: 'small'
                        },
                        on: {
                            click: async () => {
                                this.$Modal.confirm({
                                    title: this.L('Tips'),
                                    content: this.L('Delete Company relationship Confirm'),
                                    okText: this.L('Yes'),
                                    cancelText: this.L('No'),
                                    onOk: async () => {
                                        await this.$store.dispatch({
                                            type: 'companyRelationship/delete',
                                            data: params.row
                                        })
                                        await this.getpage();
                                    }
                                })
                            }
                        }
                    }, this.L('Delete'))
                ])
            }
        }]

        async created() {
            this.pagerequest.companyId = parseInt(this.$attrs["companyId"]);
            await this.getpage();
        }
    }
