
    import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
    import AbpBase from '../../../../lib/abpbase'
    import CompanyAction from '../../../../store/entities/companycontext/companyaction'


    @Component
    export default class CompanyActionForm extends AbpBase {
        @Prop({type: Boolean, default: false}) value: boolean;
        companyAction: CompanyAction = new CompanyAction();
        private companyActionTypesList: any;

        data() {
            return {
                companyActionTypesList: []
            }
        };

        visibleChange(value: boolean) {
            if (!value) {
                this.$emit('input', value);
            } else {
                this.companyAction = new CompanyAction();
                this.companyAction.companyId = this.$store.state.companyAction.companyId;
            }
        }

        async save() {
            await this.$store.dispatch({
                type: 'companyAction/create',
                data: this.companyAction
            });
            (this.$refs.companyRelationshipForm as any).resetFields();
            this.$emit('save-success');
            this.$emit('input', false);
        }

        cancel() {
            (this.$refs.companyRelationshipForm as any).resetFields();
            this.$emit('input', false);
            this.$router.back();
        }

        async mounted() {
            this.companyActionTypesList = await this.$store.dispatch('companyAction/getCompanyActionTypes');
        }
    }
