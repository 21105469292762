
    import {Component, Prop} from 'vue-property-decorator';
    import AbpBase from '../../../../lib/abpbase'
    import CompanyInstruction from '../../../../store/entities/companycontext/companyinstruction'
    import Util from "@/lib/util";
    import RemoteSelect from "@/components/remote-select.vue";
    import KeyWordLookUpRequest from "@/store/entities/key-word-look-up-request";
    import abpWeb = abp.localization.abpWeb;


    @Component({
        components: {RemoteSelect}
    })
    export default class CompanyInstructionForm extends AbpBase {
        @Prop({type: Boolean, default: false}) value: boolean;
        @Prop({type: Boolean, default: false}) public toEdit: boolean;
        companyInstruction: CompanyInstruction = new CompanyInstruction();
        private vehicleTypes: any;
        private deliveryAddresses: any;
        private collectionAddresses: any;
        private companiesConfigurations: any;
        private companyInstructionTypes: any;

        private isImport() {
            if (this.companyInstruction) {
                return this.companyInstruction.instructionType === 1;
            }
            return false;
        }

        private isExport() {
            if (this.companyInstruction) {
                return this.companyInstruction.instructionType === 2;
            }
            return false;
        }

        data() {
            return {
                vehicleTypes: [],
                deliveryAddresses: [],
                collectionAddresses: [],
                companiesConfigurations: [],
                companyInstructionTypes: []
            }
        };

        getCode(property: string) {
            if (this.companiesConfigurations === null || this.companiesConfigurations.length === 0) {
                return '';
            }
            return this.companiesConfigurations.find(x => x.property === property).code;
        }

        async visibleChange(value: boolean) {
            if (!value) {
                this.$emit('input', value);
            } else {
                this.companyInstruction = Util.extend(true, {}, this.$store.state.companyInstruction.editCompanyInstruction);
                await this.loadCollectionAddresses();
                await this.loadDeliveryAddresses();
            }
        }

        async searchCallBack(request: KeyWordLookUpRequest) {
            return await this.$store.dispatch('company/keyWordLookUp', request);
        }

        async loadDeliveryAddresses() {
            this.deliveryAddresses = await this.loadCompanyAddresses(this.companyInstruction.consigneeId);
        }

        async loadCollectionAddresses() {
            this.collectionAddresses = await this.loadCompanyAddresses(this.companyInstruction.supplierId)
        }

        async loadCompanyAddresses(companyId: number) {
            if (companyId) {
                return await this.$store.dispatch('address/lookUp', companyId);
            } else {
                return [];
            }
        }

        async save() {
            await this.$store.dispatch({
                type: this.toEdit ? 'companyInstruction/update' : 'companyInstruction/create',
                data: this.companyInstruction
            });
            (this.$refs.companyInstructionForm as any).resetFields();
            this.$emit('save-success');
            this.$emit('input', false);
        }

        cancel() {
            (this.$refs.companyInstructionForm as any).resetFields();
            this.$emit('input', false);
        }

        async mounted() {
            this.vehicleTypes = await this.$store.dispatch('vehicletype/lookUp');
            this.companiesConfigurations = await this.$store.dispatch('companyInstruction/getCompaniesConfiguration');
            this.companyInstructionTypes = await this.$store.dispatch('companyInstruction/getTypes')
        }
    }
