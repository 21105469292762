
    import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
    import Util from '../../../../lib/util'
    import AbpBase from '../../../../lib/abpbase'
    import CompanyRelationship from '../../../../store/entities/companycontext/companyrelationship'

    class CompanyLookUpRequest {
        keyWord: string;
        companyId: number;
        id: number | null;
    }

    @Component
    export default class CompanyRelationshipForm extends AbpBase {
        @Prop({type: Boolean, default: false}) value: boolean;
        @Prop({type: Boolean, default: false}) public toEdit: boolean;
        request: CompanyLookUpRequest = new CompanyLookUpRequest();
        companyRelationship: CompanyRelationship = new CompanyRelationship();

        companies: any;
        categories: any;
        countries: any;

        data() {
            return {
                companies: [],
                countries: [],
                categories: []
            }
        };

        async mounted() {
            this.categories = await this.$store.dispatch('category/lookUp');
            this.countries = await this.$store.dispatch('country/lookUp');
        }

        async isCompanyTextChanged(val: string) {
            if (val) {
                this.request.keyWord = val;
                await this.getCompanies();
            }
        }

        async getCompanies() {
            this.request.id = this.companyRelationship.relatedCompanyId;
            this.companies = await this.$store.dispatch({
                type: 'company/filteredLookUp',
                data: this.request
            })
        }

        props: {
            companyId: number
        }

        async visibleChange(value: boolean) {
            if (!value) {
                this.$emit('input', value);
            } else {
                this.companyRelationship = Util.extend(true, {}, this.$store.state.companyRelationship.editCompanyRelationship);
                await this.getCompanies();
            }
        }

        async save() {
            await this.$store.dispatch({
                type: this.toEdit ? 'companyRelationship/update' : 'companyRelationship/create',
                data: this.companyRelationship
            });
            (this.$refs.companyRelationshipForm as any).resetFields();
            this.$emit('save-success');
            this.$emit('input', false);
        }

        cancel() {
            (this.$refs.companyRelationshipForm as any).resetFields();
            this.$emit('input', false);
            this.$router.back();
        }

        async created() {
            this.request.companyId = parseInt(this.$attrs["companyId"]);
            this.companyRelationship.companyId = this.request.companyId;
        }
    }
